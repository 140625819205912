// import React, { useState } from 'react';
// import { BsFillBellFill, BsFillEnvelopeFill } from 'react-icons/bs';
// import { IoLogOut } from 'react-icons/io5';
// import { Row, Col } from 'react-bootstrap';
// import { useNavigate } from 'react-router-dom';
// import '../Sidebar/Sidebar.css';

// const Header = () => {
//   const [searchQuery, setSearchQuery] = useState('');
//   const navigate = useNavigate();

//   const handleBellClick = () => {
//     navigate('/notifications');
//   };

//   const handleEnvelopeClick = () => {
//     navigate('/messages');
//   };

//   const handleButtonClick = () => {
//     localStorage.removeItem('userData');
//     navigate('/');
//   };

//   const handleSearch = (e) => {
//     e.preventDefault(); // Prevents the default form submission behavior
//     // Perform search logic here
//     console.log('Search query:', searchQuery);
//     // For example, you can navigate to a search results page or filter results
//     // navigate(`/search?query=${encodeURIComponent(searchQuery)}`);
//   };


  
//   return (
//     <div>
//       <Row className="p-3" style={{backgroundColor:'#fae3df'}}>
//         <Col>
//           <nav className='mt-3'>
//             <div style={{ marginLeft: '40px' }}>
//               <form className="d-flex" role="search" onSubmit={handleSearch}>
//                 <input
//                   className="form-control"
//                   type="search"
//                   placeholder="Search"
//                   aria-label="Search"
//                   value={searchQuery}
//                   onChange={(e) => setSearchQuery(e.target.value)}
//                 />
//               </form>
//             </div>
//           </nav>
//         </Col>
//         <Col>
//           <div className='text-end'>
//             <BsFillBellFill
//               className='icon-hover m-3'
//               style={{ cursor: 'pointer' }}
//               onClick={handleBellClick}
//             />
//             <BsFillEnvelopeFill
//               className='icon-hover m-3'
//               style={{ cursor: 'pointer' }}
//               onClick={handleEnvelopeClick}
//             />
//             <button
//               className='icon-hover m-3'
//               style={{ background: 'none', border: 'none', outline: 'none', cursor: 'pointer', fontSize: '20px' }}
//               onClick={handleButtonClick}
//             >
//               <IoLogOut />
//             </button>
//           </div>
//         </Col>
//       </Row>
//     </div>
//   );
// }

// export default Header;


import React, { useState, useEffect } from 'react';
import { BsFillBellFill, BsFillEnvelopeFill } from 'react-icons/bs';
import { IoLogOut } from 'react-icons/io5';
import { Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import '../Sidebar/Sidebar.css';

const Header = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [userType, setUserType] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch the userType from sessionStorage
    const type = sessionStorage.getItem('userType');
    setUserType(type);
  }, []);

  const handleBellClick = () => {
    navigate('/notifications');
  };

  const handleEnvelopeClick = () => {
    navigate('/messages');
  };

  const handleButtonClick = () => {
    localStorage.removeItem('userData');
    sessionStorage.removeItem('userType'); // Clear userType on logout
    navigate('/');
  };

  const handleSearch = (e) => {
    e.preventDefault(); // Prevents the default form submission behavior
    // Perform search logic here
    console.log('Search query:', searchQuery);
    // For example, you can navigate to a search results page or filter results
    // navigate(`/search?query=${encodeURIComponent(searchQuery)}`);
  };

  return (
    <div>
      <Row className="p-3" style={{ backgroundColor: '#fae3df' }}>
        <Col>
          <nav className='mt-3'>
            {userType === 'admin' && (
              <div style={{ marginLeft: '40px' }}>
                <form className="d-flex" role="search" onSubmit={handleSearch}>
                  <input
                    className="form-control"
                    type="search"
                    placeholder="Search"
                    aria-label="Search"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                </form>
              </div>
            )}
          </nav>
        </Col>
        <Col>
          <div className='text-end'>
            {userType === 'admin' && (
              <>
                {/* <BsFillBellFill
                  className='icon-hover m-3'
                  style={{ cursor: 'pointer' }}
                  onClick={handleBellClick}
                />
                <BsFillEnvelopeFill
                  className='icon-hover m-3'
                  style={{ cursor: 'pointer' }}
                  onClick={handleEnvelopeClick}
                /> */}
              </>
            )}
            <button
              className='icon-hover m-3'
              style={{ background: 'none', border: 'none', outline: 'none', cursor: 'pointer', fontSize: '20px' }}
              onClick={handleButtonClick}
            >
              <IoLogOut />
            </button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Header;
