// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { BsFillArchiveFill, BsFillGrid3X3GapFill, BsPeopleFill,  } from 'react-icons/bs';
// import { FiBox } from "react-icons/fi";
// import { BarChart, Bar, LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
// import Sidebar from '../Sidebar/Sidebar';
// import Header from '../Head/Header';

// const Dashboard = () => {
//   const [productCount, setProductCount] = useState(0);
//   const [categoryCount, setCategoryCount] = useState(2); 
//   const [customerCount, setCustomerCount] = useState(0);
//   const [alertCount, setAlertCount] = useState(0);
//   const [sidebarOpen, setSidebarOpen] = useState(false);



//   useEffect(() => {
//     // Fetch products from the API
//     const fetchProducts = async () => {
//       try {
//         const response = await axios.get('https://sjpapi.prettywomen.in/API/v1/get-products');
//         setProductCount(response.data.length); 
//       } catch (err) {
//         console.error('Failed to fetch products:', err);
//       }
//     };

//     fetchProducts();
//   }, []);

//   return (
//     <div className="d-flex">
//       <Sidebar />
//       <div className='w-100'>
//         <Header />
//         <div className="container mt-4" style={{ fontFamily: 'Arial, sans-serif' }}>
//           <h3>Pretty Women Admin Dashboard</h3>
//           <div className="row mt-4">
//             <DashboardCard title="PRODUCTS" count={productCount} icon={<BsFillArchiveFill style={{ cursor: 'pointer' }} />} backgroundColor="#d09691" />
//             <DashboardCard title="CATEGORIES" count={categoryCount} icon={<BsFillGrid3X3GapFill style={{ cursor: 'pointer' }} />} backgroundColor="#e17a70" />
//             <DashboardCard title="CUSTOMERS" count={customerCount} icon={<BsPeopleFill style={{ cursor: 'pointer' }} />} backgroundColor="#b36d62" />
//             <DashboardCard title="ORDERS" count={alertCount} icon={<FiBox style={{ cursor: 'pointer' }} />} backgroundColor="#b42c2c" />
//           </div>
         
//         </div>
//       </div>
//     </div>
//   );
// };

// const DashboardCard = ({ title, count, icon, backgroundColor }) => (
//   <div className="col-md-3">
//     <div className="card" style={{ backgroundColor }}>
//       <div className="card-body">
//         <div className="d-flex justify-content-between align-items-center">
//           <h5 className="card-title">{title}</h5>
//           {icon}
//         </div>
//         <h1 className="mt-3">{count}</h1>
//       </div>
//     </div>
//   </div>
// );

// export default Dashboard;



import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BsFillArchiveFill, BsFillGrid3X3GapFill, BsPeopleFill } from 'react-icons/bs';
import { FiBox } from "react-icons/fi";
import Sidebar from '../Sidebar/Sidebar';
import Header from '../Head/Header';

const Dashboard = () => {
  const [productCount, setProductCount] = useState(0);
  const [categoryCount, setCategoryCount] = useState(2); 
  const [customerCount, setCustomerCount] = useState(0);
  const [alertCount, setAlertCount] = useState(0);
  const [transactions, setTransactions] = useState([]); // New state for transactions
  const [sidebarOpen, setSidebarOpen] = useState(false);

  useEffect(() => {
    // Fetch products from the API
    const fetchProducts = async () => {
      try {
        const response = await axios.get('https://sjpapi.prettywomen.in/API/v1/get-products');
        setProductCount(response.data.length); 
      } catch (err) {
        console.error('Failed to fetch products:', err);
      }
    };

    // Fetch transactions from the API
    const fetchTransactions = async () => {
      try {
        const response = await axios.get('https://sjpapi.prettywomen.in/API/v1/api/transactions/details');
        setTransactions(response.data);
        setAlertCount(response.data.length); // Set alertCount based on transactions length
      } catch (error) {
        console.error('Error fetching transactions:', error);
      }
    };

    fetchProducts();
    fetchTransactions(); // Call the fetchTransactions function
  }, []);

  return (
    <div className="d-flex">
      <Sidebar />
      <div className='w-100'>
        <Header />
        <div className="container mt-4" style={{ fontFamily: 'Arial, sans-serif' }}>
          <h3>Pretty Women Admin Dashboard</h3>
          <div className="row mt-4">
            <DashboardCard title="PRODUCTS" count={productCount} icon={<BsFillArchiveFill style={{ cursor: 'pointer' }} />} backgroundColor="#d09691" />
            <DashboardCard title="CATEGORIES" count={categoryCount} icon={<BsFillGrid3X3GapFill style={{ cursor: 'pointer' }} />} backgroundColor="#e17a70" />
            <DashboardCard title="CUSTOMERS" count={customerCount} icon={<BsPeopleFill style={{ cursor: 'pointer' }} />} backgroundColor="#b36d62" />
            <DashboardCard title="ORDERS" count={alertCount} icon={<FiBox style={{ cursor: 'pointer' }} />} backgroundColor="#b42c2c" />
          </div>
        </div>
      </div>
    </div>
  );
};

const DashboardCard = ({ title, count, icon, backgroundColor }) => (
  <div className="col-md-3">
    <div className="card" style={{ backgroundColor }}>
      <div className="card-body">
        <div className="d-flex justify-content-between align-items-center">
          <h5 className="card-title">{title}</h5>
          {icon}
        </div>
        <h1 className="mt-3">{count}</h1>
      </div>
    </div>
  </div>
);

export default Dashboard;
