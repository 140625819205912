import React, { useState, useEffect } from 'react';
import Sidebar from '../Sidebar/Sidebar';
import Header from '../Head/Header';
import { Table, Container } from 'react-bootstrap';
import axios from 'axios';

const Customers = () => {
  const [customers, setCustomers] = useState([]);
  const [error, setError] = useState(null);

  // Fetch customer data when the component mounts
  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const response = await axios.get('https://sjpapi.prettywomen.in/API/v1/customer'); // Adjust the URL as necessary
        setCustomers(response.data);
      } catch (err) {
        setError('Failed to fetch customer data');
      }
    };

    fetchCustomers();
  }, []);

  if (error) return <div>{error}</div>;

  return (
    <div className="d-flex">
      <Sidebar />
      <div className="w-100">
        <Header />
        <Container className="my-4">
          <h5>Customers Details</h5>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>Username</th>
                <th>Mobile Number</th>
                <th>Email ID</th>
                <th>Billing Address</th>
            
              </tr>
            </thead>
            <tbody>
              {customers.length > 0 ? (
                customers.map((customer, index) => (
                  <tr key={index}>
                    <td>{customer.username}</td>
                    <td>{customer.mobile}</td>
                    <td>{customer.email}</td>
                    <td>
                      {customer.billingAddress ? `${customer.billingAddress.address}, ${customer.billingAddress.city}, ${customer.billingAddress.state}, ${customer.billingAddress.zip}` : 'N/A'}
                    </td>                 
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5">No customer data available</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Container>
      </div>
    </div>
  );
};

export default Customers;
